import { put, takeLatest, all, call } from 'redux-saga/effects'
import { ACTION_GET_CONTRACT_DETAIL } from 'src/redux/actions/confirmContractAction/confirmContractActions'
import instance from 'src/services/api'
import { getContractDetailActionActionSuccess } from 'src/redux/actions/confirmContractAction'
import { Endpoint } from '../Endpoint'

function* getListContractSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.CONTRACT_DETAIL, { params: action?.payload })
    }
    const response = yield call(configRequest)
    yield put(getContractDetailActionActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getContractDetailActionActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* confirmContarctSaga() {
  yield all([takeLatest(ACTION_GET_CONTRACT_DETAIL, getListContractSaga)])
}
