const SETTING_NOTIFICATION_ACTION = 'SETTING_NOTIFICATION_ACTION'
const SETTING_NOTIFICATION_ACTION_SUCCESS = 'SETTING_NOTIFICATION_ACTION_SUCCESS'
const GET_SETTING_NOTIFICATION_ACTION = 'GET_SETTING_NOTIFICATION_ACTION'
const GET_SETTING_NOTIFICATION_ACTION_SUCCESS = 'GET_SETTING_NOTIFICATION_ACTION_SUCCESS'

export {
  SETTING_NOTIFICATION_ACTION,
  SETTING_NOTIFICATION_ACTION_SUCCESS,
  GET_SETTING_NOTIFICATION_ACTION,
  GET_SETTING_NOTIFICATION_ACTION_SUCCESS,
}
