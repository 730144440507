import {
  ACTION_GET_CHART_LIST,
  ACTION_GET_CHART_LIST_SUCCESS,
} from 'src/redux/actions/getChartListAction/getChartListActionList'

const INITIAL_STATE = {
  data: {},
  loading: false,
}

const getChartListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_CHART_LIST:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_CHART_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default getChartListReducer
