import { FORM_SUBMIT_BY_LOGIN, FORM_SUBMIT_BY_LOGIN_SUCCESS } from './formSubmitByLoginActions'

export const formSubmitByLogin = (payload) => {
  return {
    type: FORM_SUBMIT_BY_LOGIN,
    payload,
  }
}

export const formSubmitByLoginSuccess = (payload) => {
  return {
    type: FORM_SUBMIT_BY_LOGIN_SUCCESS,
    payload,
  }
}
