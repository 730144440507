import React from 'react'
import { useTranslation } from 'react-i18next'
import { LINK_URL } from 'src/utils/constants'

export function ListLink() {
  const { t } = useTranslation()

  return (
    <div className="footer-list-container">
      <div className="footer-list-screen">
        <a
          target="_blank"
          href={LINK_URL.FOOTER.URL_1}
          rel="noreferrer"
          className="footer-text-list"
        >
          {t('footer.term')}
        </a>
        <a
          target="_blank"
          href={LINK_URL.FOOTER.URL_2}
          rel="noreferrer"
          className="footer-text-list"
        >
          {t('footer.policy')}
        </a>
      </div>
    </div>
  )
}
