import { put, takeLatest, all, call } from 'redux-saga/effects'
import { checkEmailCodeSuccess, postResetLoginIdSuccess } from 'src/redux/actions/resetLoginId'
import {
  ACTION_POST_CHECK_EMAIL_CODE,
  ACTION_POST_RESET_LOGIN_ID,
} from 'src/redux/actions/resetLoginId/resetLoginIdActions'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* resetLoginId(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.SETTING_EMAIL, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(postResetLoginIdSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(postResetLoginIdSuccess({ status: false, code: code, message: msg }))
  }
}

function* checkEmailCode(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.CHECK_EMAIL_CODE, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(checkEmailCodeSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(checkEmailCodeSuccess({ status: false, code: code, message: msg }))
  }
}

export default function* resetLoginIdSaga() {
  yield all([takeLatest(ACTION_POST_RESET_LOGIN_ID, resetLoginId)])
  yield all([takeLatest(ACTION_POST_CHECK_EMAIL_CODE, checkEmailCode)])
}
