import {
  ACTION_GET_NOTI_DETAIL,
  ACTION_GET_NOTI_DETAIL_SUCCESS,
  ACTION_GET_NOTI_LIST,
  ACTION_GET_NOTI_LIST_SUCCESS,
  ACTION_CHANGE_NOTI_SETTINGS,
  ACTION_CHANGE_NOTI_SETTINGS_SUCCESS,
  ACTION_GET_NOTICE_UNREAD_COMMON,
  ACTION_GET_NOTICE_UNREAD_COMMON_SUCCESS,
  ACTION_RESET_NOTI,
} from 'src/redux/actions/notiAction/notiActionList'

const INITIAL_STATE = {
  data: {},
  loading: false,
  dataEdit: {},
}

const notiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_RESET_NOTI:
      return {
        ...state,
        data: null,
        loading: false,
      }
    case ACTION_GET_NOTI_LIST:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_NOTI_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case ACTION_GET_NOTI_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_NOTI_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case ACTION_CHANGE_NOTI_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case ACTION_CHANGE_NOTI_SETTINGS_SUCCESS:
      return {
        ...state,
        dataEdit: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

const noticeCommonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_NOTICE_UNREAD_COMMON:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_NOTICE_UNREAD_COMMON_SUCCESS:
      return {
        ...state,
        data: action?.payload,
        loading: false,
      }
    default:
      return state
  }
}

export { notiReducer, noticeCommonReducer }
