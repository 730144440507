import {
  ACTION_GET_CONTRACT_DETAIL,
  ACTION_GET_CONTRACT_DETAIL_RESET,
  ACTION_GET_CONTRACT_DETAIL_SUCCESS,
} from './confirmContractActions'

export const getContractDetailByIdAction = (payload) => {
  return {
    type: ACTION_GET_CONTRACT_DETAIL,
    payload,
  }
}
export const getContractDetailActionActionSuccess = (payload) => {
  return {
    type: ACTION_GET_CONTRACT_DETAIL_SUCCESS,
    payload,
  }
}
export const getContractDetailResetAction = (payload) => {
  return {
    type: ACTION_GET_CONTRACT_DETAIL_RESET,
    payload,
  }
}
