import {
  GET_SETTING_NOTIFICATION_ACTION,
  GET_SETTING_NOTIFICATION_ACTION_SUCCESS,
  SETTING_NOTIFICATION_ACTION,
  SETTING_NOTIFICATION_ACTION_SUCCESS,
} from './changeNotiSettingActions'

export const settingNotificationAction = (payload) => {
  return {
    type: SETTING_NOTIFICATION_ACTION,
    payload,
  }
}

export const settingNotificationActionSuccess = (payload) => {
  return {
    type: SETTING_NOTIFICATION_ACTION_SUCCESS,
    payload,
  }
}

export const getSettingNotificationAction = (payload) => {
  return {
    type: GET_SETTING_NOTIFICATION_ACTION,
    payload,
  }
}

export const getSettingNotificationActionSuccess = (payload) => {
  return {
    type: GET_SETTING_NOTIFICATION_ACTION_SUCCESS,
    payload,
  }
}
