const ACTION_GET_LIST_CONTRACT = 'ACTION_GET_LIST_CONTRACT'
const ACTION_GET_LIST_CONTRACT_SUCCESS = 'ACTION_GET_LIST_CONTRACT_SUCCESS'
const ACTION_GET_LIST_CONTRACT_RESET = 'ACTION_GET_LIST_CONTRACT_RESET'
const ACTION_GET_DETAIL_CONTRACT = 'ACTION_GET_DETAIL_CONTRACT'
const ACTION_GET_DETAIL_CONTRACT_SUCCESS = 'ACTION_GET_DETAIL_CONTRACT_SUCCESS'
const ACTION_GET_EMERGENCY = 'ACTION_GET_EMERGENCY'
const ACTION_GET_EMERGENCY_SUCCESS = 'ACTION_GET_EMERGENCY_SUCCESS'
const ACTION_GET_EMERGENCY_RESET = 'ACTION_GET_EMERGENCY_RESET'

export {
  ACTION_GET_LIST_CONTRACT,
  ACTION_GET_LIST_CONTRACT_SUCCESS,
  ACTION_GET_LIST_CONTRACT_RESET,
  ACTION_GET_DETAIL_CONTRACT,
  ACTION_GET_DETAIL_CONTRACT_SUCCESS,
  ACTION_GET_EMERGENCY,
  ACTION_GET_EMERGENCY_SUCCESS,
  ACTION_GET_EMERGENCY_RESET,
}
