import { put, takeLatest, all, call } from 'redux-saga/effects'
import {
  getSettingNotificationActionSuccess,
  settingNotificationActionSuccess,
} from 'src/redux/actions/changeNotiSetting'
import {
  GET_SETTING_NOTIFICATION_ACTION,
  SETTING_NOTIFICATION_ACTION,
} from 'src/redux/actions/changeNotiSetting/changeNotiSettingActions'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* settingNotification(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.SETTING_NOTIFICATION_EMAIL, action.payload)
    }
    const response = yield call(configRequest)
    yield put(settingNotificationActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      settingNotificationActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* getSettingNotification() {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.GET_SETTING_NOTIFICATION_EMAIL)
    }
    const response = yield call(configRequest)
    yield put(getSettingNotificationActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getSettingNotificationActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* settingNotificationSaga() {
  yield all([takeLatest(SETTING_NOTIFICATION_ACTION, settingNotification)])
  yield all([takeLatest(GET_SETTING_NOTIFICATION_ACTION, getSettingNotification)])
}
