import {
  ACTION_POST_CHECK_EMAIL_CODE,
  ACTION_POST_CHECK_EMAIL_CODE_RESET,
  ACTION_POST_CHECK_EMAIL_CODE_SUCCESS,
  ACTION_POST_RESET_LOGIN_ID,
  ACTION_POST_RESET_LOGIN_ID_RESET,
  ACTION_POST_RESET_LOGIN_ID_SUCCESS,
} from 'src/redux/actions/resetLoginId/resetLoginIdActions'

const INITIAL_STATE = {
  dataVerify: {},
  loading: false,
}

const INITIAL_STATE_CHECK_MAIL_CODE = {
  dataVerifyCheckEmailCode: {},
  loading: false,
}

const resetLoginIdReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_POST_RESET_LOGIN_ID:
      return {
        ...state,
        loading: true,
      }
    case ACTION_POST_RESET_LOGIN_ID_SUCCESS:
      return {
        ...state,
        dataVerify: action.payload,
        loading: false,
      }
    case ACTION_POST_RESET_LOGIN_ID_RESET:
      return {
        ...state,
        dataVerify: {},
        loading: false,
      }
    default:
      return state
  }
}

const checkEmailCodeReducer = (state = INITIAL_STATE_CHECK_MAIL_CODE, action) => {
  switch (action.type) {
    case ACTION_POST_CHECK_EMAIL_CODE:
      return {
        ...state,
        loading: true,
      }
    case ACTION_POST_CHECK_EMAIL_CODE_SUCCESS:
      return {
        ...state,
        dataVerifyCheckEmailCode: action.payload,
        loading: false,
      }
    case ACTION_POST_CHECK_EMAIL_CODE_RESET:
      return {
        ...state,
        dataVerifyCheckEmailCode: {},
        loading: false,
      }
    default:
      return state
  }
}

export { resetLoginIdReducer, checkEmailCodeReducer }
