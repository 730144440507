import { combineReducers } from 'redux'
import loginReducer from './login'
import changeSidebar from './changeSidebar'
import getChartListReducer from './getChartListReducer'
import { notiReducer, noticeCommonReducer } from './notiReducer'
import energySavingReducer from './energySavingReducer'
import securitySafetyReducer from './securitySafetyReducer'
import contactReducer from './contactReducer'
import formQAReducer from './formQAReducer'
import contractDetailReducer from './contractDetailReducer'
import companyInfoReducer from './companyInfoReducer'
import { monthContractReducer, feeDetailReducer, meterDetailReducer } from './monthContractReducer'
import { ACTION_LOGOUT } from '../actions/loginAction/loginActionList'
import registerReducer from './registerReducer'
import gasMeterReducer from './gasMeterReducer'
import electricMeterReducer from './electricMeterReducer'
import compareUsageReducer from './compareUsageReducer'
import resetPasswordReducer from './resetPasswordReducer'
import changePasswordReducer from './changePasswordReducer'
import settingNotificationReducer from './changeNotiSettingReducer'
import categoryListReducer from './categoryListReducer'
import formSubmitByLoginReducer from './formSubmitByLoginReducer'
import { checkEmailCodeReducer, resetLoginIdReducer } from './resetLoginId'
import listShopReducer from './listShopReducer'

const allReducers = combineReducers({
  loginReducer: loginReducer,
  changeSidebar: changeSidebar,
  getChartListReducer: getChartListReducer,
  notiReducer: notiReducer,
  noticeCommonReducer: noticeCommonReducer,
  energySavingReducer: energySavingReducer,
  securitySafetyReducer: securitySafetyReducer,
  contactReducer: contactReducer,
  formQAReducer: formQAReducer,
  contractDetailReducer: contractDetailReducer,
  companyInfoReducer: companyInfoReducer,
  monthContractReducer: monthContractReducer,
  feeDetailReducer: feeDetailReducer,
  meterDetailReducer: meterDetailReducer,
  registerReducer: registerReducer,
  gasMeterReducer: gasMeterReducer,
  electricMeterReducer: electricMeterReducer,
  compareUsageReducer: compareUsageReducer,
  resetPasswordReducer: resetPasswordReducer,
  changePasswordReducer: changePasswordReducer,
  settingNotificationReducer: settingNotificationReducer,
  categoryListReducer: categoryListReducer,
  formSubmitByLoginReducer: formSubmitByLoginReducer,
  resetLoginIdReducer: resetLoginIdReducer,
  checkEmailCodeReducer: checkEmailCodeReducer,
  listShopReducer: listShopReducer,
})

const rootReducer = (state, action) => {
  if (action.type === ACTION_LOGOUT) {
    localStorage.removeItem('persist:root')
    return allReducers(undefined, action)
  }
  return allReducers(state, action)
}

export default rootReducer
