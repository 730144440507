import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import MainLayout from 'src/views/layout/MainLayout'
import { mainRoutes } from './routes'

const MainRouter = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {mainRoutes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          )
        })}
        <Route path="*" element={<Navigate to="top" replace />} />
      </Route>
    </Routes>
  )
}

export default MainRouter
