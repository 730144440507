import {
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_ACTION_SUCCESS,
  FORGOT_PASSWORD_RESET_ACTION,
  ACTION_RESET_PASSWORD,
  ACTION_RESET_PASSWORD_SUCCESS,
  ACTION_CHECK_TOKEN_RESET_PASSWORD,
  ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS,
} from 'src/redux/actions/resetPasswordAction/resetPasswordActions'

const INITIAL_STATE = {
  dataCheckMail: {},
  dataResetPassword: {},
  checkTokenChangePassword: {},
  loading: false,
}

const resetPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_ACTION:
      return {
        ...state,
        loading: true,
      }
    case FORGOT_PASSWORD_RESET_ACTION:
      return {
        ...state,
        loading: false,
        dataCheckMail: {},
        dataResetPassword: {},
        checkTokenChangePassword: {},
      }
    case FORGOT_PASSWORD_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCheckMail: action.payload,
      }
    case ACTION_RESET_PASSWORD:
      return {
        ...state,
        loading: false,
      }
    case ACTION_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        dataResetPassword: action.payload,
        loading: false,
      }
    case ACTION_CHECK_TOKEN_RESET_PASSWORD:
      return {
        ...state,
        loading: false,
      }
    case ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        checkTokenChangePassword: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default resetPasswordReducer
