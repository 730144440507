import { put, takeLatest, all, call } from 'redux-saga/effects'
import { FORM_SUBMIT_BY_LOGIN } from 'src/redux/actions/formSubmitByLogin/formSubmitByLoginActions'
import { formSubmitByLoginSuccess } from 'src/redux/actions/formSubmitByLogin'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* formSubmitByLogin(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.FORM_REQUEST_BY_LOGIN, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(formSubmitByLoginSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      formSubmitByLoginSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* formSubmitByLoginSaga() {
  yield all([takeLatest(FORM_SUBMIT_BY_LOGIN, formSubmitByLogin)])
}
