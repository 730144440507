import { put, takeLatest, all, call } from 'redux-saga/effects'
import instance from 'src/services/api'
import {
  ACTION_GET_NOTI_DETAIL,
  ACTION_GET_NOTI_LIST,
  ACTION_GET_NOTICE_UNREAD_COMMON,
} from 'src/redux/actions/notiAction/notiActionList'
import {
  getNoticeUnreadCommomSuccess,
  getNotiDetailActionSuccess,
  getNotiListActionSuccess,
} from 'src/redux/actions/notiAction'
import { Endpoint } from '../Endpoint'
import { generatePath } from 'react-router-dom'

function* getNotiList(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.NOTIFICATION_LIST, { params: action?.payload })
    }
    const response = yield call(configRequest)
    yield put(getNotiListActionSuccess(response?.data))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getNotiListActionSuccess({
        status: status,
        code: code,
        message: msg,
      }),
    )
  }
}

function* getNotiDetail(action) {
  try {
    const url = generatePath(Endpoint.NOTIFICATION_DETAIL, {
      id: action?.payload,
    })
    const configRequest = () => {
      return instance.get(url)
    }
    const response = yield call(configRequest)
    yield put(getNotiDetailActionSuccess(response?.data))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getNotiDetailActionSuccess({
        status: status,
        code: code,
        message: msg,
      }),
    )
  }
}

function* getNoticeUnreadCommon(action) {
  try {
    const url = generatePath(Endpoint.NOTICE_UNREAD_SUMMARY, {
      id: action?.payload,
    })
    const configRequest = () => {
      return instance.get(url)
    }
    const response = yield call(configRequest)
    yield put(getNoticeUnreadCommomSuccess(response?.data))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getNoticeUnreadCommomSuccess({
        status: status,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* getNotiListSaga() {
  yield all([
    takeLatest(ACTION_GET_NOTI_LIST, getNotiList),
    takeLatest(ACTION_GET_NOTI_DETAIL, getNotiDetail),
    takeLatest(ACTION_GET_NOTICE_UNREAD_COMMON, getNoticeUnreadCommon),
  ])
}
