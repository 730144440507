import {
  ACTION_GET_LIST_SHOP,
  ACTION_GET_LIST_SHOP_SUCCESS,
} from 'src/redux/actions/listShop/listShopAction'

const INITIAL_STATE = {
  listShop: [],
  loading: false,
}

const listShopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_LIST_SHOP:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_LIST_SHOP_SUCCESS:
      return {
        ...state,
        listShop: action?.payload?.results,
        loading: false,
      }
    default:
      return state
  }
}

export default listShopReducer
