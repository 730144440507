import {
  ACTION_GET_ELECTRIC_METER_DETAIL,
  ACTION_GET_ELECTRIC_METER_DETAIL_SUCCESS,
} from './ElectricMeterActions'

export const getElectricMeterDetailAction = (payload) => {
  return {
    type: ACTION_GET_ELECTRIC_METER_DETAIL,
    payload,
  }
}

export const getElectricMeterDetailSuccessAction = (payload) => {
  return {
    type: ACTION_GET_ELECTRIC_METER_DETAIL_SUCCESS,
    payload,
  }
}
