import {
  ACTION_GET_LIST_CONTRACT,
  ACTION_GET_DETAIL_CONTRACT,
  ACTION_GET_DETAIL_CONTRACT_SUCCESS,
  ACTION_GET_LIST_CONTRACT_SUCCESS,
  ACTION_GET_EMERGENCY,
  ACTION_GET_EMERGENCY_SUCCESS,
  ACTION_GET_LIST_CONTRACT_RESET,
  ACTION_GET_EMERGENCY_RESET,
} from './securitySafeActions'

export const getListContractAction = (payload) => {
  return {
    type: ACTION_GET_LIST_CONTRACT,
    payload,
  }
}

export const getListContractSuccessAction = (payload) => {
  return {
    type: ACTION_GET_LIST_CONTRACT_SUCCESS,
    payload,
  }
}

export const resetListContractAction = (payload) => {
  return {
    type: ACTION_GET_LIST_CONTRACT_RESET,
    payload,
  }
}

export const getDetailContractAction = (payload) => {
  return {
    type: ACTION_GET_DETAIL_CONTRACT,
    payload,
  }
}

export const getDetailContractSuccessAction = (payload) => {
  return {
    type: ACTION_GET_DETAIL_CONTRACT_SUCCESS,
    payload,
  }
}

export const getEmergencyAction = (payload) => {
  return {
    type: ACTION_GET_EMERGENCY,
    payload,
  }
}

export const getEmergencySuccessAction = (payload) => {
  return {
    type: ACTION_GET_EMERGENCY_SUCCESS,
    payload,
  }
}

export const resetEmergencyAction = (payload) => {
  return {
    type: ACTION_GET_EMERGENCY_RESET,
    payload,
  }
}
