import React from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { CButton } from '@coreui/react'

export function ButtonAuth(props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, setVisible, visible } = props
  const location = useLocation()

  return user?.result?.access_token !== undefined ? (
    <CButton
      className="btn-orange btn-auth mx-2 hidden-on-mobile"
      onClick={() => setVisible(!visible)}
    >
      {t('auth.log_out')}
    </CButton>
  ) : (
    location.pathname !== '/login' && (
      <CButton
        className="btn-orange btn-auth mx-2"
        onClick={() => {
          navigate('/login')
        }}
      >
        {t('auth.login.login')}
      </CButton>
    )
  )
}

ButtonAuth.propTypes = {
  user: PropTypes.any,
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
}
