import React from 'react'
import { cilHome, cilSun, cilBell, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CNavItem } from '@coreui/react'
import i18n from 'src/configs/i18n'

const nav = [
  {
    component: CNavItem,
    name: i18n.t('sideBar.top'),
    to: '/top',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: i18n.t('sideBar.energySaving'),
    to: '/energy-saving?page=1',
    icon: <CIcon icon={cilSun} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: i18n.t('sideBar.notification'),
    to: '/notification?page=1&priority_flag=1',
    icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
    badge: {
      text: '3',
    },
  },
  {
    component: CNavItem,
    name: i18n.t('sideBar.myPage'),
    to: '/my-page',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
]

export default nav
