import { put, takeLatest, all, call } from 'redux-saga/effects'
import {
  FORGOT_PASSWORD_ACTION,
  ACTION_CHECK_TOKEN_RESET_PASSWORD,
  ACTION_RESET_PASSWORD,
} from 'src/redux/actions/resetPasswordAction/resetPasswordActions'
import instance from 'src/services/api'
import {
  forgotPasswordSuccessAction,
  checkTokenChangePasswordSuccessAction,
  resetPasswordSuccessAction,
} from 'src/redux/actions/resetPasswordAction'
import { Endpoint } from '../Endpoint'

function* checkTokenChangePassword(action) {
  try {
    const configRequest = () => {
      return instance.post(
        Endpoint.CHECK_EMAIL_EXIST_TOKEN_RESET_PASSWORD + `?token=${action?.payload}`,
      )
    }
    const response = yield call(configRequest)
    yield put(checkTokenChangePasswordSuccessAction(response.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      checkTokenChangePasswordSuccessAction({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* forgotPassword(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.FORGOT_PASSWORD, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(forgotPasswordSuccessAction(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      forgotPasswordSuccessAction({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* resetNewPasswordSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.RESET_PASS_WORD, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(resetPasswordSuccessAction(response.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      resetPasswordSuccessAction({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* forgotPasswordSaga() {
  yield all([takeLatest(FORGOT_PASSWORD_ACTION, forgotPassword)])
  yield all([takeLatest(ACTION_RESET_PASSWORD, resetNewPasswordSaga)])
  yield all([takeLatest(ACTION_CHECK_TOKEN_RESET_PASSWORD, checkTokenChangePassword)])
}
