import { SidebarNav } from './SidebarNav'
import React, { useEffect, useState } from 'react'
import { CSidebar, CSidebarNav } from '@coreui/react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import nav from './navItems'
import { useSelector } from 'react-redux'

const Sidebar = () => {
  const noticeCommonReducer = useSelector((state) => state.noticeCommonReducer || [])
  const [newListNav, setNewListNav] = useState(nav)

  useEffect(() => {
    if (noticeCommonReducer?.data && noticeCommonReducer?.data?.results) {
      if (noticeCommonReducer?.data?.results?.total_unread <= 99) {
        newListNav[2].badge.text = noticeCommonReducer?.data?.results?.total_unread || 0
      } else newListNav[2].badge.text = '99+'
      setNewListNav([...newListNav])
    }
  }, [noticeCommonReducer])

  return (
    <CSidebar position="fixed" className="sidebar-mitsubachi">
      <CSidebarNav>
        <SimpleBar>
          <SidebarNav items={newListNav} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  )
}

export default Sidebar
