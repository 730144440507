import { ACTION_GET_CHART_LIST, ACTION_GET_CHART_LIST_SUCCESS } from './getChartListActionList'

export const getChartListAction = (payload) => {
  return {
    type: ACTION_GET_CHART_LIST,
    payload,
  }
}

export const getChartListActionSuccess = (payload) => {
  return {
    type: ACTION_GET_CHART_LIST_SUCCESS,
    payload,
  }
}
