import React from 'react'
import { CModal, CSpinner } from '@coreui/react'
import 'src/scss/_loading.scss'
import PropTypes from 'prop-types'

const Loading = ({ isShow }) => {
  return (
    <CModal
      className="loading-container"
      keyboard={false}
      portal={false}
      visible={isShow}
      alignment={'center'}
    >
      <CSpinner color="warning" />
    </CModal>
  )
}
Loading.defaultProps = {
  isShow: false,
}

Loading.prototype = {
  isShow: PropTypes.bool,
}
export default Loading
