import {
  ACTION_GET_COMPARE_USAGE_DATA_SUCCESS,
  ACTION_GET_COMPARE_USAGE_DATA,
} from 'src/redux/actions/compareUsage/CompareUsageActions'

const INITIAL_STATE = {
  compareData: {},
  loading: false,
}

const compareUsageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_COMPARE_USAGE_DATA:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_COMPARE_USAGE_DATA_SUCCESS:
      return {
        ...state,
        compareData: action.payload,
        loading: false,
      }
    default:
      return INITIAL_STATE
  }
}

export default compareUsageReducer
