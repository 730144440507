const ACTION_LOGIN = 'ACTION_LOGIN'
const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS'
const ACTION_LOGOUT = 'ACTION_LOGOUT'
const ACTION_LOGOUT_NOT_ALLOW = 'ACTION_LOGOUT_NOT_ALLOW'
const ACTION_LOGOUT_SUCCESS = 'ACTION_LOGOUT_SUCCESS'
const ACTION_LOGIN_RESET = 'ACTION_LOGIN_RESET'
const ACTION_GET_COMMON_NOTICE = 'ACTION_GET_COMMON_NOTICE'
const ACTION_GET_COMMON_NOTICE_SUCCESS = 'ACTION_GET_COMMON_NOTICE_SUCCESS'

export {
  ACTION_LOGIN,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGOUT,
  ACTION_LOGOUT_NOT_ALLOW,
  ACTION_LOGOUT_SUCCESS,
  ACTION_LOGIN_RESET,
  ACTION_GET_COMMON_NOTICE,
  ACTION_GET_COMMON_NOTICE_SUCCESS,
}
