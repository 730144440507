import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from 'src/components/layout/footer/Footer'
import Header from 'src/components/layout/header/Header'

const AuthLayout = () => {
  return (
    <>
      <div className="min-vh-100 layout-auth d-flex flex-column">
        <Header />
        <div className="d-flex align-items-center justify-content-center layout-auth-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default AuthLayout
