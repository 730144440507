import {
  ACTION_GET_CONTRACT_DETAIL,
  ACTION_GET_CONTRACT_DETAIL_RESET,
  ACTION_GET_CONTRACT_DETAIL_SUCCESS,
} from 'src/redux/actions/confirmContractAction/confirmContractActions'

const INITIAL_STATE = {
  contractDetail: {},
  loading: false,
}

const contractDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_CONTRACT_DETAIL:
      return {
        ...state,
        contractDetail: {},
        loading: true,
      }
    case ACTION_GET_CONTRACT_DETAIL_SUCCESS:
      return {
        ...state,
        contractDetail: action.payload,
        loading: false,
      }
    case ACTION_GET_CONTRACT_DETAIL_RESET:
      return {
        ...state,
        contractDetail: {},
        loading: false,
      }
    default:
      return state
  }
}

export default contractDetailReducer
