import React, { useState } from 'react'
import { CHeader } from '@coreui/react'
import { useSelector } from 'react-redux'
import HeaderLogo from 'src/assets/images/header-logo.svg'
import { LogOutModal } from 'src/components/common/logout-modal/LogoutModal'
import { BurgerMenu } from './BurgerMenu'
import { ButtonAuth } from './ButtonAuth'
import { ListLink } from './ListLink'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const { user } = useSelector((state) => state.loginReducer)

  const access_token = useSelector(
    (state) => state?.loginReducer?.user?.result?.access_token || undefined,
  )

  return (
    <>
      <CHeader className="header-custom">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <img
            className="cursor-pointer"
            src={HeaderLogo}
            alt="header-logo"
            onClick={() => {
              if (access_token) {
                navigate('/top')
              } else {
                navigate('/login')
              }
            }}
          />
          <div className="d-flex justify-content-end align-items-center">
            <ListLink />
            <ButtonAuth user={user} setVisible={setVisible} visible={visible} />
            <BurgerMenu user={user} setVisible={setVisible} visible={visible} />
          </div>
        </div>
      </CHeader>
      <LogOutModal visible={visible} setVisible={setVisible} />
    </>
  )
}

export default Header
