import { ACTION_POST_DATA, ACTION_POST_DATA_SUCCESS } from './formQAActions'

export const postDataQAAction = (payload) => {
  return {
    type: ACTION_POST_DATA,
    payload,
  }
}

export const postDataQAActionSuccess = (payload) => {
  return {
    type: ACTION_POST_DATA_SUCCESS,
    payload,
  }
}
