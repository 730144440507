import {
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_ACTION_SUCCESS,
  FORGOT_PASSWORD_RESET_ACTION,
  ACTION_RESET_PASSWORD,
  ACTION_RESET_PASSWORD_SUCCESS,
  ACTION_CHECK_TOKEN_RESET_PASSWORD,
  ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS,
} from './resetPasswordActions'

export const forgotPasswordResetAction = (payload) => {
  return {
    type: FORGOT_PASSWORD_RESET_ACTION,
    payload,
  }
}

export const forgotPasswordAction = (payload) => {
  return {
    type: FORGOT_PASSWORD_ACTION,
    payload,
  }
}

export const forgotPasswordSuccessAction = (payload) => {
  return {
    type: FORGOT_PASSWORD_ACTION_SUCCESS,
    payload,
  }
}

export const resetPasswordAction = (payload) => {
  return {
    type: ACTION_RESET_PASSWORD,
    payload,
  }
}

export const resetPasswordSuccessAction = (payload) => {
  return {
    type: ACTION_RESET_PASSWORD_SUCCESS,
    payload,
  }
}

export const checkTokenChangePasswordAction = (payload) => {
  return {
    type: ACTION_CHECK_TOKEN_RESET_PASSWORD,
    payload,
  }
}

export const checkTokenChangePasswordSuccessAction = (payload) => {
  return {
    type: ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS,
    payload,
  }
}
