import {
  ACTION_GET_LIST_CONTRACT,
  ACTION_GET_DETAIL_CONTRACT,
  ACTION_GET_DETAIL_CONTRACT_SUCCESS,
  ACTION_GET_LIST_CONTRACT_SUCCESS,
  ACTION_GET_EMERGENCY,
  ACTION_GET_EMERGENCY_SUCCESS,
  ACTION_GET_LIST_CONTRACT_RESET,
  ACTION_GET_EMERGENCY_RESET,
} from 'src/redux/actions/securitySafetyAction/securitySafeActions'

const INITIAL_STATE = {
  listContract: [],
  detailContract: {},
  emergency: {},
  loading: true,
}

const securitySafetyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_LIST_CONTRACT:
      return {
        ...state,
        listContract: [],
        loading: true,
      }
    case ACTION_GET_LIST_CONTRACT_SUCCESS:
      return {
        ...state,
        listContract: action.payload,
        loading: false,
      }
    case ACTION_GET_LIST_CONTRACT_RESET:
      return {
        ...state,
        listContract: [],
        loading: false,
      }
    case ACTION_GET_DETAIL_CONTRACT:
      return {
        ...state,
        detailContract: {},
        loading: true,
      }
    case ACTION_GET_DETAIL_CONTRACT_SUCCESS:
      return {
        ...state,
        detailContract: action.payload,
        loading: false,
      }
    case ACTION_GET_EMERGENCY:
      return {
        ...state,
        emergency: {},
        loading: true,
      }
    case ACTION_GET_EMERGENCY_SUCCESS:
      return {
        ...state,
        emergency: action.payload,
        loading: false,
      }
    case ACTION_GET_EMERGENCY_RESET:
      return {
        ...state,
        emergency: {},
        loading: false,
      }
    default:
      return state
  }
}

export default securitySafetyReducer
