import { all, fork } from 'redux-saga/effects'
import getContactListSaga from './contact/getList'
import getEnergySavingSaga from './energySaving/getList'
import submitFormQASaga from './formQA/submitFormQA'
import getChartListSaga from './getChartList/getChartList'
import getNotiListSaga from './noti/getNotiList'
import securitySafetySaga from './securitySafety/securitySafetySaga'
import confirmContarctSaga from './confirmContract/confirmContractSagas'
import loginSaga from './login/loginSaga'
import getCompanyInfoSaga from './companyInfo/companyInfo'
import getMonthContractSaga from './monthContract/monthContract'
import registerSaga from './register/registerSaga'
import gasMeterSaga from './gasMeter/gasMeter'
import electricMeterSaga from './electricMeter/electricMeter'
import compareUsageSaga from './compareUsage/compareUsage'
import resetPasswordSaga from './resetPassword/resetPasswordSaga'
import resetLoginIdSaga from './resetLoginId/resetLoginIdSaga'
import changePasswordSaga from './changePassword/changePasswordSaga'
import settingNotificationSaga from './changeNotiSetting/changeNotiSettingSaga'
import categoryListSaga from './categoryList/categoryListSaga'
import formSubmitByLoginSaga from './formSubmitByLogin/formSubmitByLoginSaga'
import listShopSaga from './listShop/listShopSaga'

export default function* rootSaga() {
  yield all([fork(getChartListSaga)])
  yield all([fork(getNotiListSaga)])
  yield all([fork(getEnergySavingSaga)])
  yield all([fork(securitySafetySaga)])
  yield all([fork(getContactListSaga)])
  yield all([fork(submitFormQASaga)])
  yield all([fork(confirmContarctSaga)])
  yield all([fork(loginSaga)])
  yield all([fork(getCompanyInfoSaga)])
  yield all([fork(getMonthContractSaga)])
  yield all([fork(registerSaga)])
  yield all([fork(gasMeterSaga)])
  yield all([fork(electricMeterSaga)])
  yield all([fork(compareUsageSaga)])
  yield all([fork(resetPasswordSaga)])
  yield all([fork(resetLoginIdSaga)])
  yield all([fork(changePasswordSaga)])
  yield all([fork(settingNotificationSaga)])
  yield all([fork(categoryListSaga)])
  yield all([fork(formSubmitByLoginSaga)])
  yield all([fork(listShopSaga)])
}
