import {
  GET_CATEGORY_LIST_ACTION,
  GET_CATEGORY_LIST_ACTION_SUCCESS,
} from 'src/redux/actions/categoryList/categoryListActions'

const INITIAL_STATE = {
  listCategory: [],
  loading: false,
}

const categoryListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORY_LIST_ACTION:
      return {
        ...state,
        loading: true,
      }
    case GET_CATEGORY_LIST_ACTION_SUCCESS:
      return {
        ...state,
        listCategory: action?.payload?.results,
        loading: false,
      }
    default:
      return state
  }
}
export default categoryListReducer
