import {
  ACTION_GET_COMPARE_USAGE_DATA,
  ACTION_GET_COMPARE_USAGE_DATA_SUCCESS,
} from './CompareUsageActions'

export const getCompareUsaggeAction = (payload) => {
  return {
    type: ACTION_GET_COMPARE_USAGE_DATA,
    payload,
  }
}

export const getCompareUsaggeActionSuccess = (payload) => {
  return {
    type: ACTION_GET_COMPARE_USAGE_DATA_SUCCESS,
    payload,
  }
}
