import {
  ACTION_GET_ENERGY_SAVING_LIST,
  ACTION_GET_ENERGY_SAVING_LIST_SUCCESS,
  ACTION_GET_ENERGY_SAVING_RESET,
} from './energySavingActionList'

export const energySavingActionReset = (payload) => {
  return {
    type: ACTION_GET_ENERGY_SAVING_RESET,
    payload,
  }
}

export const getEnergySavingListAction = (payload) => {
  return {
    type: ACTION_GET_ENERGY_SAVING_LIST,
    payload,
  }
}

export const getEnergySavingListActionSuccess = (payload) => {
  return {
    type: ACTION_GET_ENERGY_SAVING_LIST_SUCCESS,
    payload,
  }
}
