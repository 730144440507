import { put, takeLatest, all, call } from 'redux-saga/effects'
import instance from 'src/services/api'
import { ACTION_GET_CHART_LIST } from 'src/redux/actions/getChartListAction/getChartListActionList'
import { getChartListActionSuccess } from 'src/redux/actions/getChartListAction'

function* getChartList(action) {
  try {
    const configRequest = () => {
      return instance.get('/chart_list')
    }
    const response = yield call(configRequest)
    if (response?.data?.error) {
      console.log(response.data.error)
    } else {
      yield put(getChartListActionSuccess(response?.data))
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* getChartListSaga() {
  yield all([takeLatest(ACTION_GET_CHART_LIST, getChartList)])
}
