import {
  FORM_SUBMIT_BY_LOGIN,
  FORM_SUBMIT_BY_LOGIN_SUCCESS,
} from 'src/redux/actions/formSubmitByLogin/formSubmitByLoginActions'

const INITIAL_STATE = {
  data: [],
  loading: false,
}

const formSubmitByLoginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FORM_SUBMIT_BY_LOGIN:
      return {
        ...state,
        loading: true,
      }
    case FORM_SUBMIT_BY_LOGIN_SUCCESS:
      return {
        ...state,
        data: action?.payload,
        loading: false,
      }
    default:
      return INITIAL_STATE
  }
}
export default formSubmitByLoginReducer
