import { put, takeLatest, all, call } from 'redux-saga/effects'
import { getCompareUsaggeActionSuccess } from 'src/redux/actions/compareUsage'
import { ACTION_GET_COMPARE_USAGE_DATA } from 'src/redux/actions/compareUsage/CompareUsageActions'
import instance from 'src/services/api'

function* compareUsage(action) {
  try {
    const configRequest = () => {
      return instance.get(`/compare_usage?type=${action.payload}`)
    }
    const response = yield call(configRequest)
    if (response?.data?.error) {
      console.log(response.data.error)
    } else {
      yield put(getCompareUsaggeActionSuccess(response?.data))
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* compareUsageSaga() {
  yield all([takeLatest(ACTION_GET_COMPARE_USAGE_DATA, compareUsage)])
}
