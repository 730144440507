import React from 'react'
import { CButton, CModal } from '@coreui/react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'

export function Modal(props) {
  const { t } = useTranslation()
  const {
    visible,
    onClose,
    content,
    txtOK,
    txtClose,
    isCustom = false,
    isSmall = false,
    component,
    onSubmit,
    isTwoButton = false,
    isShowIconClose = true,
  } = props

  const _renderOneButton = () => {
    return (
      <div className="modal-noti-btn">
        <CButton
          onClick={() => {
            onClose && onClose()
            onSubmit && onSubmit()
          }}
        >
          {txtClose || t('modal.btn_cancel')}
        </CButton>
      </div>
    )
  }

  const _renderTwoButton = () => {
    return (
      <div className="modal-noti-btn">
        <CButton className="modal-noti-btn-cancel me-3" onClick={onClose}>
          {txtClose || t('modal.btn_cancel')}
        </CButton>
        <CButton
          className="modal-noti-btn-ok ms-3"
          onClick={() => {
            onClose && onClose()
            onSubmit && onSubmit()
          }}
        >
          {txtOK || t('modal.btn_ok')}
        </CButton>
      </div>
    )
  }

  const _renderModal = () => {
    if (isCustom) {
      return <>{component}</>
    } else {
      return (
        <div className="modal-noti d-flex flex-column align-items-center">
          <div className="modal-noti-title">{content}</div>
          {isTwoButton ? _renderTwoButton() : _renderOneButton()}
        </div>
      )
    }
  }

  // const _renderIconClose = () => {
  //   return (
  //     <div className="modal-noti-icon-close">
  //       <img onClick={onClose} src={iconClose} alt="" />
  //     </div>
  //   )
  // }

  return (
    <CModal
      backdrop="static"
      size={isSmall ? `sm` : `lg`}
      visible={visible}
      onClose={onClose}
      alignment="center"
    >
      {/*{isShowIconClose && _renderIconClose()}*/}
      {_renderModal()}
    </CModal>
  )
}

Modal.prototype = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string,
  txtOK: PropTypes.string,
  txtClose: PropTypes.string,
  isCustom: PropTypes.bool,
  isSmall: PropTypes.bool,
  component: PropTypes.func,
  onSubmit: PropTypes.func,
  isTwoButton: PropTypes.bool,
  isShowIconClose: PropTypes.bool,
}
