import { ACTION_GET_CONTACT_LIST, ACTION_GET_CONTACT_LIST_SUCCESS } from './contactActions'

export const getContactListAction = (payload) => {
  return {
    type: ACTION_GET_CONTACT_LIST,
    payload,
  }
}

export const getContactListActionSuccess = (payload) => {
  return {
    type: ACTION_GET_CONTACT_LIST_SUCCESS,
    payload,
  }
}
