import { put, takeLatest, all, call } from 'redux-saga/effects'
import { getCompanyInfoSuccess } from 'src/redux/actions/companyInfo'
import { ACTION_GET_COMPANY_INFO } from 'src/redux/actions/companyInfo/companyInfoAction'
import instance from 'src/services/api'

function* getCompanyInfo(action) {
  try {
    const configRequest = () => {
      return instance.get('/company_info')
    }
    const response = yield call(configRequest)
    if (response?.data?.error) {
    } else {
      yield put(getCompanyInfoSuccess(response?.data))
    }
  } catch (error) {}
}

export default function* getCompanyInfoSaga() {
  yield all([takeLatest(ACTION_GET_COMPANY_INFO, getCompanyInfo)])
}
