import { REGISTER } from 'redux-persist'
import {
  REGISTER_RESET,
  REGISTER_EMAIL,
  REGISTER_EMAIL_SUCCESS,
  REGISTER_CHECK_EMAIL_TOKEN,
  REGISTER_CHECK_EMAIL_TOKEN_SUCCESS,
  REGISTER_CONTRACT_INFO_SUCCESS,
  REGISTER_CONTRACT_INFO,
  REGISTER_SUCCESS,
} from 'src/redux/actions/registerAction/registerActions'

const INITIAL_STATE = {
  dataRegisterContractInfo: {},
  dataContract: {},
  dataRegister: {},
  dataCheckRegister: {},
  dataRegisterEmail: {},
  dataCheckMailToken: {},
  loading: false,
}

const registerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_RESET:
      return {
        ...state,
        loading: false,
        dataContract: {},
        dataRegister: {},
        dataCheckRegister: {},
        dataRegisterContractInfo: {},
        dataRegisterEmail: {},
        dataCheckMailToken: {},
      }
    case REGISTER_EMAIL:
      return {
        ...state,
        loading: true,
        dataRegisterEmail: {},
      }
    case REGISTER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataRegisterEmail: action.payload,
      }
    case REGISTER_CONTRACT_INFO:
      return {
        ...state,
        dataRegisterContractInfo: {},
        loading: true,
      }
    case REGISTER_CONTRACT_INFO_SUCCESS:
      return {
        ...state,
        dataRegisterContractInfo: action?.payload,
        loading: false,
      }
    case REGISTER_CHECK_EMAIL_TOKEN:
      return {
        ...state,
        loading: true,
        dataCheckMailToken: action.payload,
      }
    case REGISTER_CHECK_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCheckMailToken: action.payload,
      }
    case REGISTER:
      return {
        ...state,
        dataCheckRegister: {},
        loading: true,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCheckRegister: action.payload,
      }
    default:
      return state
  }
}

export default registerReducer
