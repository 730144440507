import { put, takeLatest, all, call } from 'redux-saga/effects'
import { getContactListActionSuccess } from 'src/redux/actions/contact'
import { ACTION_GET_CONTACT_LIST } from 'src/redux/actions/contact/contactActions'
import instance from 'src/services/api'

function* getContactList(action) {
  try {
    const configRequest = () => {
      return instance.get(`/list_contact?${action.payload}`)
    }
    const response = yield call(configRequest)
    if (response?.data?.error) {
      console.log(response.data.error)
    } else {
      yield put(getContactListActionSuccess(response?.data))
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* getContactListSaga() {
  yield all([takeLatest(ACTION_GET_CONTACT_LIST, getContactList)])
}
