import {
  GET_SETTING_NOTIFICATION_ACTION,
  GET_SETTING_NOTIFICATION_ACTION_SUCCESS,
  SETTING_NOTIFICATION_ACTION,
  SETTING_NOTIFICATION_ACTION_SUCCESS,
} from 'src/redux/actions/changeNotiSetting/changeNotiSettingActions'

const INITIAL_STATE = {
  settingReceiveNotificationMail: true,
  changeSuccess: false,
  loading: false,
}

const settingNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETTING_NOTIFICATION_ACTION:
      return {
        ...state,
        changeSuccess: false,
        loading: true,
      }
    case SETTING_NOTIFICATION_ACTION_SUCCESS:
      return {
        ...state,
        settingReceiveNotificationMail: action.payload?.result,
        changeSuccess: true,
        loading: false,
      }
    case GET_SETTING_NOTIFICATION_ACTION:
      return {
        ...state,
        changeSuccess: false,
        loading: true,
      }
    case GET_SETTING_NOTIFICATION_ACTION_SUCCESS:
      return {
        ...state,
        settingReceiveNotificationMail: action.payload?.results,
        loading: false,
      }
    default:
      return state
  }
}

export default settingNotificationReducer
