import { put, takeLatest, all, call } from 'redux-saga/effects'
import { changePasswordActionSuccess } from 'src/redux/actions/changePassword'
import { CHANGE_PASSWORD_ACTION } from 'src/redux/actions/changePassword/changePasswordActions'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* changePassword(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.SETTING_PASSWORD, action.payload)
    }
    const response = yield call(configRequest)
    yield put(changePasswordActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      changePasswordActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* changePasswordSaga() {
  yield all([takeLatest(CHANGE_PASSWORD_ACTION, changePassword)])
}
