import {
  ACTION_GET_GAS_METER_DETAIL,
  ACTION_GET_GAS_METER_DETAIL_SUCCESS,
  ACTION_GET_GAS_METER_DETAIL_RESET,
} from 'src/redux/actions/gasMeter/GasMeterActions'

const INITIAL_STATE = {
  gasMeterDetail: {},
  loading: false,
}

const gasMeterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_GAS_METER_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_GAS_METER_DETAIL_SUCCESS:
      return {
        ...state,
        gasMeterDetail: action.payload,
        loading: false,
      }
    case ACTION_GET_GAS_METER_DETAIL_RESET:
      return {
        ...state,
        gasMeterDetail: {},
        loading: false,
      }
    default:
      return state
  }
}

export default gasMeterReducer
