import { GET_CATEGORY_LIST_ACTION, GET_CATEGORY_LIST_ACTION_SUCCESS } from './categoryListActions'

export const getCategoryList = (payload) => {
  return {
    type: GET_CATEGORY_LIST_ACTION,
    payload,
  }
}

export const getCategoryListSuccess = (payload) => {
  return {
    type: GET_CATEGORY_LIST_ACTION_SUCCESS,
    payload,
  }
}
