import {
  ACTION_GET_ELECTRIC_METER_DETAIL,
  ACTION_GET_ELECTRIC_METER_DETAIL_SUCCESS,
} from 'src/redux/actions/electricMeter/ElectricMeterActions'

const INITIAL_STATE = {
  electricMeterDetail: {},
  loading: false,
}

const electricMeterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_ELECTRIC_METER_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_ELECTRIC_METER_DETAIL_SUCCESS:
      return {
        ...state,
        electricMeterDetail: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default electricMeterReducer
