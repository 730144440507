import {
  ACTION_POST_CHECK_EMAIL_CODE,
  ACTION_POST_CHECK_EMAIL_CODE_RESET,
  ACTION_POST_CHECK_EMAIL_CODE_SUCCESS,
  ACTION_POST_RESET_LOGIN_ID,
  ACTION_POST_RESET_LOGIN_ID_RESET,
  ACTION_POST_RESET_LOGIN_ID_SUCCESS,
} from './resetLoginIdActions'

export const postResetLoginId = (payload) => {
  return {
    type: ACTION_POST_RESET_LOGIN_ID,
    payload,
  }
}

export const postResetLoginIdSuccess = (payload) => {
  return {
    type: ACTION_POST_RESET_LOGIN_ID_SUCCESS,
    payload,
  }
}

export const resetPostResetLoginId = (payload) => {
  return {
    type: ACTION_POST_RESET_LOGIN_ID_RESET,
    payload,
  }
}

export const checkEmailCode = (payload) => {
  return {
    type: ACTION_POST_CHECK_EMAIL_CODE,
    payload,
  }
}

export const checkEmailCodeSuccess = (payload) => {
  return {
    type: ACTION_POST_CHECK_EMAIL_CODE_SUCCESS,
    payload,
  }
}

export const resetCheckEmailCode = (payload) => {
  return {
    type: ACTION_POST_CHECK_EMAIL_CODE_RESET,
    payload,
  }
}
