const BASE_URL = process.env.REACT_APP_API_URL

const LINK_URL = {
  FOOTER: {
    URL_1: 'https://beenavi.jp/wp-content/uploads/2023/12/みつばちナビ利用規約20231201.pdf',
    URL_2: 'https://mitsuwasangyo.mhdg.co.jp/privacy-policy/',
  },
  HEADER: {
    URL_1: 'https://beenavi.jp/',
    URL_2: 'https://mitsuwasangyo.mhdg.co.jp/faq-beenavi/',
  },
  LOGIN: {
    ABOUT_HONEYBEE_APP: '',
  },
  REGISTER: {
    TERMS_OF_USE: '',
    CHECK_CUSTOMER:
      'https://mitsuwasangyo.mhdg.co.jp/ufaqs/%e9%96%8b%e8%a8%ad%e7%94%bb%e9%9d%a2%e3%81%ae%e6%25a%5B%E2%80%A6%5D7%e3%81%a8%e3%81%af%e4%bd%95%e3%81%a7%e3%81%99%e3%81%8b/',
    TERM: 'https://beenavi.jp/userterms/',
    POSTCARD: 'https://beenavi.jp/userterms-remarks/',
  },
  FORM_BY_NOT_LOGIN: {
    IQUIRIES_ABOUT_GAS_AND_ELECTRICITY_FEE: '',
  },
}

const CHART_TYPE = {
  GAS: 'GAS',
  ELECTRIC: 'ELECTRIC',
}

const EMERGENCY_INFO_LINK = 'https://mitsuwasangyo.mhdg.co.jp/faq-gas/#faq-gastrouble'
const CONTACT_LINK = 'https://mitsuwasangyo.mhdg.co.jp/contact'
const EQUIPMENT_CHECK_RESULT_LINK = '/'

export {
  BASE_URL,
  LINK_URL,
  EMERGENCY_INFO_LINK,
  EQUIPMENT_CHECK_RESULT_LINK,
  CHART_TYPE,
  CONTACT_LINK,
}
