import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AuthLayout from 'src/views/layout/AuthLayout'
import { loginRoutes } from './routes'

const AuthRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {loginRoutes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          )
        })}
        <Route path="*" element={<Navigate to="login" replace />} />
      </Route>
    </Routes>
  )
}
export default AuthRouter
