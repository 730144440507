import { put, takeLatest, all, call } from 'redux-saga/effects'
import {
  REGISTER_EMAIL,
  REGISTER_CHECK_EMAIL_TOKEN,
  REGISTER_CONTRACT_INFO,
} from 'src/redux/actions/registerAction/registerActions'
import instance from 'src/services/api'
import {
  registerMailSuccessAction,
  registerEmailTokenSuccess,
  registerContractInfoSuccessAction,
  registerSuccessAction,
} from 'src/redux/actions/registerAction'
import { Endpoint } from '../Endpoint'
import { REGISTER } from 'redux-persist'

function* registerCheckEmailTokenSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.CHECK_EMAIL_REGISTER_TOKEN, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(registerEmailTokenSuccess(response.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      registerEmailTokenSuccess({
        message: msg,
        code: code,
        status: false,
      }),
    )
  }
}

function* registerContractInfoSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.REGISTER_CONTRACT_INFO, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(registerContractInfoSuccessAction(response.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      registerContractInfoSuccessAction({
        message: msg,
        code: code,
        status: false,
      }),
    )
  }
}

function* registerFnSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.REGISTER, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(registerSuccessAction(response.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      registerSuccessAction({
        message: msg,
        code: code,
        status: false,
      }),
    )
  }
}

function* registerEmailSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.REGISTER_EMAIL, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(registerMailSuccessAction(response.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      registerMailSuccessAction({
        message: msg,
        code: code,
        status: false,
      }),
    )
  }
}

export default function* registerSaga() {
  yield all([takeLatest(REGISTER_CONTRACT_INFO, registerContractInfoSaga)])
  yield all([takeLatest(REGISTER_EMAIL, registerEmailSaga)])
  yield all([takeLatest(REGISTER_CHECK_EMAIL_TOKEN, registerCheckEmailTokenSaga)])
  yield all([takeLatest(REGISTER, registerFnSaga)])
}
