import {
  ACTION_GET_GAS_METER_DETAIL,
  ACTION_GET_GAS_METER_DETAIL_RESET,
  ACTION_GET_GAS_METER_DETAIL_SUCCESS,
} from './GasMeterActions'

export const getGasMeterDetailAction = (payload) => {
  return {
    type: ACTION_GET_GAS_METER_DETAIL,
    payload,
  }
}

export const getGasMeterDetailSuccessAction = (payload) => {
  return {
    type: ACTION_GET_GAS_METER_DETAIL_SUCCESS,
    payload,
  }
}

export const resetGetGasMeterDetailAction = (payload) => {
  return {
    type: ACTION_GET_GAS_METER_DETAIL_RESET,
    payload,
  }
}
