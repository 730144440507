const FORGOT_PASSWORD_RESET_ACTION = 'FORGOT_PASSWORD_RESET_ACTION'
const FORGOT_PASSWORD_ACTION = 'FORGOT_PASSWORD_ACTION'
const FORGOT_PASSWORD_ACTION_SUCCESS = 'FORGOT_PASSWORD_ACTION_SUCCESS'
const ACTION_RESET_PASSWORD = 'ACTION_RESET_PASSWORD'
const ACTION_RESET_PASSWORD_SUCCESS = 'ACTION_RESET_PASSWORD_SUCCESS'
const ACTION_CHECK_TOKEN_RESET_PASSWORD = 'ACTION_CHECK_TOKEN_RESET_PASSWORD'
const ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS = 'ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS'

export {
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_ACTION_SUCCESS,
  FORGOT_PASSWORD_RESET_ACTION,
  ACTION_RESET_PASSWORD,
  ACTION_RESET_PASSWORD_SUCCESS,
  ACTION_CHECK_TOKEN_RESET_PASSWORD,
  ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS,
}
