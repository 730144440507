import { ACTION_GET_LIST_SHOP, ACTION_GET_LIST_SHOP_SUCCESS } from './listShopAction'

export const getListShop = (payload) => {
  return {
    type: ACTION_GET_LIST_SHOP,
    payload,
  }
}

export const getListShopSuccess = (payload) => {
  return {
    type: ACTION_GET_LIST_SHOP_SUCCESS,
    payload,
  }
}
