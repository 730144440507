import {
  ACTION_GET_COMPANY_INFO,
  ACTION_GET_COMPANY_INFO_SUCCESS,
} from 'src/redux/actions/companyInfo/companyInfoAction'

const INITIAL_STATE = {
  data: {},
  loading: false,
}

const companyInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_COMPANY_INFO:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default companyInfoReducer
