const ACTION_GET_NOTI_LIST = 'ACTION_GET_NOTI_LIST'
const ACTION_GET_NOTI_LIST_SUCCESS = 'ACTION_GET_NOTI_LIST_SUCCESS'
const ACTION_GET_NOTI_DETAIL = 'ACTION_GET_NOTI_DETAIL'
const ACTION_GET_NOTI_DETAIL_SUCCESS = 'ACTION_GET_NOTI_DETAIL_SUCCESS'
const ACTION_CHANGE_NOTI_SETTINGS = 'ACTION_CHANGE_NOTI_SETTINGS'
const ACTION_CHANGE_NOTI_SETTINGS_SUCCESS = 'ACTION_CHANGE_NOTI_SETTINGS_SUCCESS'
const ACTION_GET_NOTICE_UNREAD_COMMON = 'ACTION_GET_NOTICE_UNREAD_COMMON'
const ACTION_GET_NOTICE_UNREAD_COMMON_SUCCESS = 'ACTION_GET_NOTICE_UNREAD_COMMON_SUCCESS'
const ACTION_RESET_NOTI = 'ACTION_RESET_NOTI'

export {
  ACTION_GET_NOTI_LIST,
  ACTION_GET_NOTI_LIST_SUCCESS,
  ACTION_GET_NOTI_DETAIL,
  ACTION_GET_NOTI_DETAIL_SUCCESS,
  ACTION_CHANGE_NOTI_SETTINGS,
  ACTION_CHANGE_NOTI_SETTINGS_SUCCESS,
  ACTION_GET_NOTICE_UNREAD_COMMON,
  ACTION_GET_NOTICE_UNREAD_COMMON_SUCCESS,
  ACTION_RESET_NOTI,
}
