import { ACTION_GET_COMPANY_INFO, ACTION_GET_COMPANY_INFO_SUCCESS } from './companyInfoAction'

export const getCompanyInfoAction = (payload) => {
  return {
    type: ACTION_GET_COMPANY_INFO,
    payload,
  }
}

export const getCompanyInfoSuccess = (payload) => {
  return {
    type: ACTION_GET_COMPANY_INFO_SUCCESS,
    payload,
  }
}
