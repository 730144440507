import { CButton, CModal } from '@coreui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutAction } from 'src/redux/actions/loginAction'
import { PropTypes } from 'prop-types'
import { Auth } from 'src/helper/Auth'

export function LogOutModal(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { visible, setVisible } = props
  const handleLogout = () => {
    dispatch(logoutAction())
    Auth.removeToken()
    navigate('/login')
  }

  return (
    <CModal visible={visible} onClose={() => setVisible(false)} className="modal-confirm-logout">
      <div className="modal-confirm d-flex flex-column justify-content-center">
        <div className="modal-confirm-header text-center">{t('auth.confirm_logout')}</div>
        <div className="modal-confirm-button d-flex justify-content-between">
          <CButton className="btn-cancel" onClick={() => setVisible(false)}>
            {t('auth.cancel')}
          </CButton>
          <CButton className="btn-logout" onClick={handleLogout}>
            {t('auth.log_out')}
          </CButton>
        </div>
      </div>
    </CModal>
  )
}

LogOutModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
}
