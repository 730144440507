import { REGISTER } from 'redux-persist'
import {
  REGISTER_RESET,
  REGISTER_EMAIL,
  REGISTER_EMAIL_SUCCESS,
  REGISTER_VERIFY_EMAIL_SUCCESS,
  GET_INFORMATION_CONTRACT__NEW_SUCCESS,
  REGISTER_CHECK_EMAIL_TOKEN,
  REGISTER_CHECK_EMAIL_TOKEN_SUCCESS,
  REGISTER_CONTRACT_INFO,
  REGISTER_CONTRACT_INFO_SUCCESS,
  REGISTER_SUCCESS,
} from './registerActions'

export const registerEmailToken = (payload) => {
  return {
    type: REGISTER_CHECK_EMAIL_TOKEN,
    payload,
  }
}

export const registerEmailTokenSuccess = (payload) => {
  return {
    type: REGISTER_CHECK_EMAIL_TOKEN_SUCCESS,
    payload,
  }
}

export const registerAction = (payload) => {
  return {
    type: REGISTER,
    payload,
  }
}

export const registerResetAction = (payload) => {
  return {
    type: REGISTER_RESET,
    payload,
  }
}

export const registerSuccessAction = (payload) => {
  return {
    type: REGISTER_SUCCESS,
    payload,
  }
}

export const registerMailAction = (payload) => {
  return {
    type: REGISTER_EMAIL,
    payload,
  }
}

export const registerMailSuccessAction = (payload) => {
  return {
    type: REGISTER_EMAIL_SUCCESS,
    payload,
  }
}

export const registerVerifyEmailSuccessAction = (payload) => {
  return {
    type: REGISTER_VERIFY_EMAIL_SUCCESS,
    payload,
  }
}

export const getInformationContractNewSuccessAction = (payload) => {
  return {
    type: GET_INFORMATION_CONTRACT__NEW_SUCCESS,
    payload,
  }
}

export const registerContractInfoAction = (payload) => {
  return {
    type: REGISTER_CONTRACT_INFO,
    payload,
  }
}

export const registerContractInfoSuccessAction = (payload) => {
  return {
    type: REGISTER_CONTRACT_INFO_SUCCESS,
    payload,
  }
}
