import {
  ACTION_GET_NOTI_DETAIL,
  ACTION_GET_NOTI_DETAIL_SUCCESS,
  ACTION_GET_NOTI_LIST,
  ACTION_GET_NOTI_LIST_SUCCESS,
  ACTION_CHANGE_NOTI_SETTINGS,
  ACTION_CHANGE_NOTI_SETTINGS_SUCCESS,
  ACTION_GET_NOTICE_UNREAD_COMMON,
  ACTION_GET_NOTICE_UNREAD_COMMON_SUCCESS,
  ACTION_RESET_NOTI,
} from './notiActionList'

export const resetNotiAction = (payload) => {
  return {
    type: ACTION_RESET_NOTI,
    payload,
  }
}

export const getNotiListAction = (payload) => {
  return {
    type: ACTION_GET_NOTI_LIST,
    payload,
  }
}

export const getNotiListActionSuccess = (payload) => {
  return {
    type: ACTION_GET_NOTI_LIST_SUCCESS,
    payload,
  }
}

export const getNotiDetailAction = (payload) => {
  return {
    type: ACTION_GET_NOTI_DETAIL,
    payload,
  }
}

export const getNotiDetailActionSuccess = (payload) => {
  return {
    type: ACTION_GET_NOTI_DETAIL_SUCCESS,
    payload,
  }
}

export const editNotiSettingsAction = (payload) => {
  return {
    type: ACTION_CHANGE_NOTI_SETTINGS,
    payload,
  }
}

export const editNotiSettingsSuccessAction = (payload) => {
  return {
    type: ACTION_CHANGE_NOTI_SETTINGS_SUCCESS,
    payload,
  }
}

export const getNoticeUnreadCommon = (payload) => {
  return {
    type: ACTION_GET_NOTICE_UNREAD_COMMON,
    payload,
  }
}

export const getNoticeUnreadCommomSuccess = (payload) => {
  return {
    type: ACTION_GET_NOTICE_UNREAD_COMMON_SUCCESS,
    payload,
  }
}
