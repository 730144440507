import {
  CHANGE_PASSWORD_ACTION,
  CHANGE_PASSWORD_ACTION_SUCCESS,
  CHANGE_PASSWORD_ACTION_SUCCESS_RESET,
} from './changePasswordActions'

export const changePasswordAction = (payload) => {
  return {
    type: CHANGE_PASSWORD_ACTION,
    payload,
  }
}

export const changePasswordActionSuccess = (payload) => {
  return {
    type: CHANGE_PASSWORD_ACTION_SUCCESS,
    payload,
  }
}

export const resetChangePasswordAction = (payload) => {
  return {
    type: CHANGE_PASSWORD_ACTION_SUCCESS_RESET,
    payload,
  }
}
