import { put, takeLatest, all, call } from 'redux-saga/effects'
import { getCategoryListSuccess } from 'src/redux/actions/categoryList'
import { GET_CATEGORY_LIST_ACTION } from 'src/redux/actions/categoryList/categoryListActions'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* getCategoryList(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.GET_CATEGORY_LIST)
    }
    const response = yield call(configRequest)
    yield put(getCategoryListSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getCategoryListSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* categoryListSaga() {
  yield all([takeLatest(GET_CATEGORY_LIST_ACTION, getCategoryList)])
}
