const ACTION_POST_RESET_LOGIN_ID = 'ACTION_POST_RESET_LOGIN_ID'
const ACTION_POST_RESET_LOGIN_ID_SUCCESS = 'ACTION_POST_RESET_LOGIN_ID_SUCCESS'
const ACTION_POST_RESET_LOGIN_ID_RESET = 'ACTION_POST_RESET_LOGIN_ID_RESET'
const ACTION_POST_CHECK_EMAIL_CODE = 'ACTION_POST_CHECK_EMAIL_CODE'
const ACTION_POST_CHECK_EMAIL_CODE_SUCCESS = 'ACTION_POST_CHECK_EMAIL_CODE_SUCCESS'
const ACTION_POST_CHECK_EMAIL_CODE_RESET = 'ACTION_POST_CHECK_EMAIL_CODE_RESET'

export {
  ACTION_POST_RESET_LOGIN_ID,
  ACTION_POST_RESET_LOGIN_ID_SUCCESS,
  ACTION_POST_RESET_LOGIN_ID_RESET,
  ACTION_POST_CHECK_EMAIL_CODE,
  ACTION_POST_CHECK_EMAIL_CODE_SUCCESS,
  ACTION_POST_CHECK_EMAIL_CODE_RESET,
}
