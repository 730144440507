import { put, takeLatest, all, call } from 'redux-saga/effects'
import { getListShopSuccess } from 'src/redux/actions/listShop'
import { ACTION_GET_LIST_SHOP } from 'src/redux/actions/listShop/listShopAction'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* getListShop(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.LIST_SHOP)
    }
    const response = yield call(configRequest)
    yield put(getListShopSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getListShopSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* listShopSaga() {
  yield all([takeLatest(ACTION_GET_LIST_SHOP, getListShop)])
}
