import {
  ACTION_LOGIN,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGOUT,
  ACTION_LOGOUT_SUCCESS,
  ACTION_LOGIN_RESET,
  ACTION_GET_COMMON_NOTICE,
  ACTION_GET_COMMON_NOTICE_SUCCESS,
  ACTION_LOGOUT_NOT_ALLOW,
} from 'src/redux/actions/loginAction/loginActionList'

const INITIAL_STATE = {
  user: {},
  loading: false,
  commonNoticeData: [],
}

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_LOGIN:
      return { ...state, loading: true }

    case ACTION_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      }
    case ACTION_LOGOUT:
      return { ...state, loading: true }

    case ACTION_LOGIN_RESET:
      return { ...state, user: {}, loading: false }

    case ACTION_LOGOUT_NOT_ALLOW:
    case ACTION_LOGOUT_SUCCESS:
      return {
        ...state,
        user: {},
        loading: false,
      }
    case ACTION_GET_COMMON_NOTICE:
      return { ...state, commonNoticeData: [], loading: true }

    case ACTION_GET_COMMON_NOTICE_SUCCESS:
      return { ...state, commonNoticeData: action?.payload?.results, loading: false }
    default:
      return state
  }
}

export default loginReducer
