import {
  ACTION_LOGIN,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGOUT,
  ACTION_LOGOUT_SUCCESS,
  ACTION_LOGIN_RESET,
  ACTION_GET_COMMON_NOTICE,
  ACTION_GET_COMMON_NOTICE_SUCCESS,
  ACTION_LOGOUT_NOT_ALLOW,
} from './loginActionList'

export const loginAction = (payload) => {
  return {
    type: ACTION_LOGIN,
    payload,
  }
}

export const loginActionSuccess = (payload) => {
  return {
    type: ACTION_LOGIN_SUCCESS,
    payload,
  }
}

export const logoutAction = (payload) => {
  return {
    type: ACTION_LOGOUT,
    payload,
  }
}

export const logoutActionNotAllow = (payload) => {
  return {
    type: ACTION_LOGOUT_NOT_ALLOW,
    payload,
  }
}

export const logoutActionSuccess = (payload) => {
  return {
    type: ACTION_LOGOUT_SUCCESS,
    payload,
  }
}

export const loginResetAction = (payload) => {
  return {
    type: ACTION_LOGIN_RESET,
    payload,
  }
}

export const getCommonNoticeAction = (payload) => {
  return {
    type: ACTION_GET_COMMON_NOTICE,
    payload,
  }
}

export const getCommonNoticeSuccessAction = (payload) => {
  return {
    type: ACTION_GET_COMMON_NOTICE_SUCCESS,
    payload,
  }
}
