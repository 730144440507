import React, { useEffect } from 'react'
import Header from 'src/components/layout/header/Header'
import Footer from 'src/components/layout/footer/Footer'
import Sidebar from 'src/components/layout/sideBar/Sidebar'
import { Outlet, useLocation } from 'react-router-dom'
import { getNoticeUnreadCommon } from '../../redux/actions/notiAction'
import { useDispatch } from 'react-redux'

const MainLayout = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (pathname?.includes('login')) return
    setTimeout(() => {
      dispatch(getNoticeUnreadCommon())
    }, 200)
  }, [pathname])

  return (
    <div>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Header />
        <div className="body flex-grow-1 app-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default MainLayout
