import { ACTION_POST_DATA, ACTION_POST_DATA_SUCCESS } from 'src/redux/actions/formQA/formQAActions'

const INITIAL_STATE = {
  data: {},
  loading: false,
}

const formQAReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_POST_DATA:
      return {
        ...state,
        loading: true,
      }
    case ACTION_POST_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default formQAReducer
