const REGISTER_CONTRACT_INFO = 'REGISTER_CONTRACT_INFO'
const REGISTER_CONTRACT_INFO_SUCCESS = 'REGISTER_CONTRACT_INFO_SUCCESS'
const REGISTER = 'REGISTER'
const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
const REGISTER_CHECK_EMAIL_TOKEN = 'REGISTER_CHECK_EMAIL_TOKEN'
const REGISTER_CHECK_EMAIL_TOKEN_SUCCESS = 'REGISTER_CHECK_EMAIL_TOKEN_SUCCESS'
const REGISTER_RESET = 'REGISTER_RESET'
const REGISTER_EMAIL = 'REGISTER_EMAIL'
const REGISTER_EMAIL_SUCCESS = 'REGISTER_EMAIL_SUCCESS'
const REGISTER_VERIFY_EMAIL = 'REGISTER_VERIFY_EMAIL'
const REGISTER_VERIFY_EMAIL_SUCCESS = 'REGISTER_VERIFY_EMAIL_SUCCESS'
const GET_INFORMATION_CONTRACT_NEW = 'GET_INFORMATION_CONTRACT_NEW'
const GET_INFORMATION_CONTRACT__NEW_SUCCESS = 'GET_INFORMATION_CONTRACT__NEW_SUCCESS'

export {
  REGISTER_CONTRACT_INFO,
  REGISTER_CONTRACT_INFO_SUCCESS,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_RESET,
  REGISTER_EMAIL,
  REGISTER_EMAIL_SUCCESS,
  REGISTER_VERIFY_EMAIL,
  REGISTER_VERIFY_EMAIL_SUCCESS,
  GET_INFORMATION_CONTRACT_NEW,
  GET_INFORMATION_CONTRACT__NEW_SUCCESS,
  REGISTER_CHECK_EMAIL_TOKEN,
  REGISTER_CHECK_EMAIL_TOKEN_SUCCESS,
}
