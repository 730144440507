import {
  CHANGE_PASSWORD_ACTION,
  CHANGE_PASSWORD_ACTION_SUCCESS,
  CHANGE_PASSWORD_ACTION_SUCCESS_RESET,
} from 'src/redux/actions/changePassword/changePasswordActions'

const INITIAL_STATE = {
  changePassword: {},
  loading: false,
}

const changePasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_ACTION:
      return {
        ...state,
        loading: true,
      }
    case CHANGE_PASSWORD_ACTION_SUCCESS:
      return {
        ...state,
        changePassword: action.payload,
        loading: false,
      }
    case CHANGE_PASSWORD_ACTION_SUCCESS_RESET:
      return {
        ...state,
        changePassword: {},
        loading: false,
      }
    default:
      return state
  }
}

export default changePasswordReducer
