import { put, takeLatest, all, call } from 'redux-saga/effects'
import { postDataQAActionSuccess } from 'src/redux/actions/formQA'
import { ACTION_POST_DATA } from 'src/redux/actions/formQA/formQAActions'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* submitFormQA(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.FORM_REQUEST_BY_NOT_LOGIN, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(postDataQAActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      postDataQAActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* submitFormQASaga() {
  yield all([takeLatest(ACTION_POST_DATA, submitFormQA)])
}
