import { put, takeLatest, all, call } from 'redux-saga/effects'
import { getGasMeterDetailSuccessAction } from 'src/redux/actions/gasMeter'
import { ACTION_GET_GAS_METER_DETAIL } from 'src/redux/actions/gasMeter/GasMeterActions'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* getGasMeterDetail(action) {
  try {
    const configRequest = () => {
      return instance.get(`${Endpoint.GAS_METER_DETAIL}?${action.payload}`)
    }
    const response = yield call(configRequest)
    yield put(getGasMeterDetailSuccessAction(response?.data))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getGasMeterDetailSuccessAction({
        status: status,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* gasMeterSaga() {
  yield all([takeLatest(ACTION_GET_GAS_METER_DETAIL, getGasMeterDetail)])
}
