import { put, takeLatest, all, call } from 'redux-saga/effects'
import {
  logoutActionSuccess,
  getCommonNoticeSuccessAction,
  loginActionSuccess,
} from 'src/redux/actions/loginAction'
import {
  ACTION_LOGIN,
  ACTION_LOGOUT,
  ACTION_GET_COMMON_NOTICE,
} from 'src/redux/actions/loginAction/loginActionList'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'
import { Auth } from 'src/helper/Auth'

function* getLoginSaga(action) {
  try {
    const { email, password, rememberEmail } = action?.payload
    const configRequest = () => {
      const params = { email, password }
      return instance.post(Endpoint.LOGIN, params)
    }
    const response = yield call(configRequest)
    if (response?.data) {
      if (rememberEmail) {
        localStorage.setItem('rememberEmail', email)
      } else {
        localStorage.setItem('rememberEmail', '')
      }
      Auth.setToken(response?.data?.result?.access_token)
    }
    yield put(loginActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      loginActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* getCommonNoticeSaga() {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.COMMON_NOTICE_LIST)
    }
    const response = yield call(configRequest)
    yield put(getCommonNoticeSuccessAction(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getCommonNoticeSuccessAction({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* logoutSaga(action) {
  try {
    yield put(logoutActionSuccess({}))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      logoutActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* loginSaga() {
  yield all([takeLatest(ACTION_LOGIN, getLoginSaga)])
  yield all([takeLatest(ACTION_LOGOUT, logoutSaga)])
  yield all([takeLatest(ACTION_GET_COMMON_NOTICE, getCommonNoticeSaga)])
}
