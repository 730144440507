import { put, takeLatest, all, call } from 'redux-saga/effects'
import {
  ACTION_GET_LIST_CONTRACT,
  ACTION_GET_DETAIL_CONTRACT,
  ACTION_GET_EMERGENCY,
} from 'src/redux/actions/securitySafetyAction/securitySafeActions'
import instance from 'src/services/api'
import {
  getListContractSuccessAction,
  getDetailContractSuccessAction,
  getEmergencySuccessAction,
} from 'src/redux/actions/securitySafetyAction'
import { Endpoint } from '../Endpoint'

function* getListContractSaga() {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.GET_LIST_CONTRACT)
    }
    const response = yield call(configRequest)
    yield put(getListContractSuccessAction(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getListContractSuccessAction({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* getDetailContractSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(`${Endpoint.CONTRACT_DETAIL}?${action.payload}`)
    }
    const response = yield call(configRequest)
    yield put(getDetailContractSuccessAction(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getDetailContractSuccessAction({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* getEmergencySaga(action) {
  try {
    const configRequest = () => {
      return instance.get(`${Endpoint.GET_EMERGENCY}?${action.payload}`)
    }
    const response = yield call(configRequest)
    yield put(getEmergencySuccessAction(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getEmergencySuccessAction({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* securitySafetySaga() {
  yield all([
    takeLatest(ACTION_GET_DETAIL_CONTRACT, getDetailContractSaga),
    takeLatest(ACTION_GET_LIST_CONTRACT, getListContractSaga),
    takeLatest(ACTION_GET_EMERGENCY, getEmergencySaga),
  ])
}
