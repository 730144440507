import React from 'react'
import { CFooter } from '@coreui/react'
import FooterLogo from 'src/assets/images/footer-logo.png'
import { useTranslation } from 'react-i18next'
import { ListLink } from './ListLink'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <CFooter>
      <div className="footer-detail">
        <div className="footer-logo-view">
          <img src={FooterLogo} alt="footer-logo" className="footer-logo" />
        </div>
        <div className="footer-text-description">
          <p>{t('footer.address')}</p>
        </div>
      </div>
      <ListLink />
    </CFooter>
  )
}

export default Footer
