import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import MainRouter from './router/MainRouter'
import AuthRouter from './router/AuthRouter'
import { useSelector } from 'react-redux'
import { caching } from './utils/caching'
import { Modal } from './components/common/modal/Modal'
import { useTranslation } from 'react-i18next'
import Loading from './components/common/loading/Loading'
import ScrollToTop from './components/common/scrollToTop/ScrollToTop'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  const { t } = useTranslation()
  const [isShowModal, setShowModal] = useState(false)
  const loadingAPI = useSelector(
    (state) =>
      state.loginReducer?.loading ||
      state.getChartListReducer?.loading ||
      state.notiReducer?.loading ||
      state.noticeCommonReducer?.loading ||
      state.energySavingReducer?.loading ||
      state.contactReducer?.loading ||
      state.formQAReducer?.loading ||
      state.monthContractReducer?.loading ||
      state.feeDetailReducer?.loading ||
      state.meterDetailReducer?.loading ||
      state.registerReducer?.loading ||
      state.gasMeterReducer?.loading ||
      state.electricMeterReducer?.loading ||
      state.compareUsageReducer?.loading ||
      state.resetPasswordReducer?.loading ||
      state.changePasswordReducer?.loading ||
      state.settingNotificationReducer?.loading ||
      state.categoryListReducer?.loading ||
      state.formSubmitByLoginReducer?.loading ||
      state.checkEmailCodeReducer?.loading ||
      state.listShopReducer?.loading,
  )

  useEffect(() => {
    caching()
  })

  useEffect(() => {
    window.addEventListener('server_error', handleShowModal)
    return () => {
      window.removeEventListener('server_error', handleShowModal)
    }
  }, [])

  const handleShowModal = (event) => {
    if (event?.detail) {
      setShowModal(true)
    }
  }

  const access_token = useSelector(
    (state) => state?.loginReducer?.user?.result?.access_token || undefined,
  )

  const renderModalCommon = () => {
    return (
      <Modal
        visible={isShowModal}
        onClose={() => setShowModal(false)}
        content={t('modal.txt_500')}
        txtClose={t('modal.btn_cancel_500')}
        isSmall={true}
      />
    )
  }

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <ScrollToTop>
          {access_token !== undefined ? <MainRouter /> : <AuthRouter />}
          {renderModalCommon()}
          <Loading isShow={loadingAPI} />
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
